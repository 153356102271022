var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "list-box" },
    [
      _c(
        "i-header",
        {
          attrs: {
            title: _vm.config["title"] ? _vm.config["title"].template : "",
            showBack: true,
          },
          on: { "back-event": _vm.toBack },
        },
        [
          _vm.config["title"] && _vm.config["title"].rightActions
            ? _c(
                "div",
                {
                  staticClass: "right_action",
                  attrs: { slot: "right" },
                  on: {
                    click: function ($event) {
                      return _vm.handleRightActions(
                        _vm.config["title"].rightActions
                      )
                    },
                  },
                  slot: "right",
                },
                [_vm._v(_vm._s(_vm.config["title"].rightActions.text))]
              )
            : _vm._e(),
        ]
      ),
      _c(
        "i-pull-refresh",
        {
          staticClass: "cnt-box",
          on: { refresh: _vm.onRefresh },
          model: {
            value: _vm.refreshing,
            callback: function ($$v) {
              _vm.refreshing = $$v
            },
            expression: "refreshing",
          },
        },
        [
          _c(
            "i-list",
            {
              attrs: {
                id: "scrollCnt",
                "immediate-check": false,
                finished: _vm.finished,
                "finished-text": _vm.finishedTxt,
              },
              on: { load: _vm.queryList },
              model: {
                value: _vm.loading,
                callback: function ($$v) {
                  _vm.loading = $$v
                },
                expression: "loading",
              },
            },
            [
              _vm.config["headline"] && _vm.config["headline"].length != 0
                ? _c(
                    _vm.$evalTemplate("Vnode", _vm.config["headline"].template),
                    { key: "static", tag: "component" }
                  )
                : _vm._e(),
              _vm.docList.length
                ? [
                    _vm._l(_vm.docList, function (item) {
                      return [
                        _c(
                          "div",
                          { key: item.$_id, attrs: { id: item.$_id } },
                          [
                            _c("ListCard", {
                              key: item.$_id,
                              attrs: {
                                info: item,
                                "btn-list": item.btns || [],
                                showBtn: true,
                                tips: item.tips,
                                template: item.template,
                                fileList: item.fileList,
                                updateFileBox: _vm.showActiveForm,
                              },
                              on: { toOptions: _vm.beforeOptions },
                            }),
                          ],
                          1
                        ),
                      ]
                    }),
                  ]
                : _vm._e(),
              _vm.isFirst
                ? _c("i-skeleton", { attrs: { title: "", row: 8 } })
                : _vm._e(),
              !_vm.docList.length && !_vm.isFirst && !_vm.loading
                ? _c("empty-box")
                : _vm._e(),
            ],
            2
          ),
        ],
        1
      ),
      !_vm.$route.query.readOnly
        ? _c("i-add-button", {
            ref: "iAddButton",
            staticStyle: { "z-index": "3" },
            attrs: { optionsList: _vm.plusOptions },
            on: { click: _vm.addDoc },
          })
        : _vm._e(),
      _c(
        "iPopup",
        {
          ref: "pop",
          style: { padding: _vm.padding, width: "100vw", height: "100vh" },
          attrs: { "get-container": "#genericListBox", position: "right" },
          model: {
            value: _vm.showActiveForm,
            callback: function ($$v) {
              _vm.showActiveForm = $$v
            },
            expression: "showActiveForm",
          },
        },
        [
          _vm.showActiveForm
            ? _c("activeForm", {
                attrs: { formConfig: _vm.activeFormConfig },
                on: { close: _vm.hideForm, send: _vm.addDocList },
              })
            : _vm._e(),
        ],
        1
      ),
      _c("ItemPop", {
        ref: "subtypePop",
        attrs: {
          "get-container": "#genericListBox",
          itemList: _vm.subtypePopList,
          title: _vm.subtypePopTitle,
        },
        on: { handlechannel: _vm.handlePopItem },
      }),
      _vm.config.listBtns && _vm.config.listBtns.length
        ? _c(
            "div",
            { staticClass: "confirm-box" },
            _vm._l(_vm.config.listBtns, function (item, index) {
              return _c(
                "i-button",
                {
                  key: index,
                  attrs: {
                    round: "",
                    color: "var(--themeColor)",
                    block: "",
                    loading: _vm.btnLoading,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.toSubmit(item)
                    },
                  },
                },
                [_vm._v("确定")]
              )
            }),
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }