var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "create-box" },
    [
      _c("i-header", {
        attrs: { title: _vm.formConfig.title },
        on: { "back-event": _vm.toBack },
      }),
      _vm.template
        ? _c(
            "div",
            { staticClass: "cnt-box" },
            [
              _c("i-tips", { attrs: { tipsList: _vm.tipsList } }),
              _c(
                "i-form",
                {
                  ref: "form",
                  attrs: { "scroll-to-error": "" },
                  on: { submit: _vm.onSubmit },
                },
                [
                  _vm._l(_vm.template[0], function (item) {
                    return [
                      _vm.refreshKey
                        ? _c("iFormItem", {
                            key: item.type + item.controlName,
                            ref: item.controlName,
                            refInFor: true,
                            attrs: {
                              formData: _vm.formData,
                              formTemplate: _vm.template,
                              "model-val": _vm.formData[item.controlName],
                              "form-item": item,
                            },
                            on: { callBack: _vm.formCallBack },
                          })
                        : _vm._e(),
                    ]
                  }),
                ],
                2
              ),
              _vm._l(_vm.fileList, function (file, index) {
                return _c("iFinderFrame", {
                  key: index,
                  attrs: {
                    mygeoLocation: _vm.myGeoLocation,
                    ismustGeoLocation: _vm.isMustGeoLocation,
                    uploaderSettings: file,
                  },
                })
              }),
              _c(
                "i-button",
                {
                  staticClass: "btn-box",
                  attrs: {
                    loading: _vm.loading,
                    disabled: _vm.loading,
                    "loading-text": "提交",
                    block: "",
                    type: "primary",
                    size: "normal",
                  },
                  on: { click: _vm.btnClick },
                },
                [_vm._v(" 提交 ")]
              ),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }