<template>
    <div class="create-box">
        <i-header :title="formConfig.title" @back-event="toBack"></i-header>
        <div v-if="template" class="cnt-box">
            <i-tips :tipsList="tipsList"></i-tips>
            <i-form ref="form" @submit="onSubmit" scroll-to-error>
                <template v-for="item in template[0]">
                    <iFormItem :key="item.type + item.controlName" v-if="refreshKey"
                               :formData="formData" :formTemplate="template"
                               :model-val="formData[item.controlName]" :ref="item.controlName"
                               :form-item="item" @callBack="formCallBack"></iFormItem>
                </template>
            </i-form>
            <iFinderFrame v-for="(file, index) in fileList" :key="index"
                          :mygeoLocation="myGeoLocation" :ismustGeoLocation="isMustGeoLocation"
                          :uploaderSettings="file"></iFinderFrame>
            <i-button class="btn-box" :loading="loading" :disabled="loading" loading-text="提交"
                      block @click="btnClick" type="primary" size="normal"> 提交 </i-button>
        </div>
    </div>
</template>

<script>
import iFormItem from "@/components/iFormItem/iFormItem";
import iFinderFrame from "@/components/iFinderFrame"
import generic from "../utils/generic";
export default {
    name: "CreateEvent",
    components: {
        iFormItem,
        iFinderFrame
    },
    props: {
        formConfig: {}
    },
    data() {
        return {
            directoryId: "",
            template: undefined,
            checkList: null,
            formData: {},
            refreshKey: true,
            isEdit: false,
            miceInfo: {},
            fieldNames: {},
            tipsList: [],
            loading: false,
            tenantID: this.$cookie.get("tenant_id"),
            fileList: [],

            myGeoLocation: {},
            isMustGeoLocation: false
        };
    },
    created() {
        console.log('formConfig:',this.formConfig)
        this.queryTemplateByTenant();
    },
    mounted() {
        // 上传文件开启定位
        if(this.formConfig.needGps) {
            this.toastInstance = this.$itoast.loading({ duration: 3000, message: "正在获取GPS", forbidClick: true });
            this.aMapLocation()
        }
        // 表单数据显示
        if (this.formConfig.formData) {
            console.log("====编辑表单数据", this.formConfig.formData, this.formConfig.updateConfig);
            setTimeout(() => {
                this.showFormDate();
            }, 200);
        }
    },
    methods: {
        btnClick() {
            this.$refs.form.validate().then(() => {
                this.$refs.form.submit()
            }).catch(() => {
                this.$itoast('请完善必填信息')
            })
        },
        aMapLocation() {
            this.isMustGeoLocation = true
            // 使用高德地图获取位置信息
            AMap.plugin("AMap.Geolocation", () => {
                var geolocation = new AMap.Geolocation({
                    enableHighAccuracy: true, //是否使用高精度定位，默认:true
                    timeout: 10000, //超过10秒后停止定位，默认：5s
                    position: "RB", //定位按钮的停靠位置
                    offset: [10, 20], //定位按钮与设置的停靠位置的偏移量，默认：[10, 20]
                    zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
                });
                geolocation.getCurrentPosition((status, georesult) => {
                    console.log("====高德地图获取到的位置信息", status,JSON.stringify(georesult))
                    if (status == "complete") {
                        AMap.plugin("AMap.Geocoder", () => {
                            var geocoder = new AMap.Geocoder();
                            var lnglat = [georesult.position.lng, georesult.position.lat];
                            geocoder.getAddress(lnglat, (status, result) => {
                                console.log("====高德地图获取到的城市信息", status,JSON.stringify(result))
                                if (status === "complete" && result.info === "OK") {
                                    // result为对应的地理位置详细信息
                                    let auditData = { province: result.regeocode.addressComponent.province, city: result.regeocode.addressComponent.city, address: result.regeocode.formattedAddress };
                                    this.myGeoLocation = {
                                        lat: georesult.position.lat,
                                        lng: georesult.position.lng,
                                        auditData,
                                    };
                                } else {
                                    this.myGeoLocation = {
                                        lat: georesult.position.lat,
                                        lng: georesult.position.lng,
                                        auditData: {},
                                    };
                                }
                            });
                        });
                    } else {
                        console.log(status,JSON.stringify(georesult))
                    }
                });
            });
        },
        // 根据租户获取创建会议模板
        queryTemplateByTenant() {
            this.fileList = this.formConfig.fileList
            let res = {
                content: {
                    template: [this.formConfig.template],
                    checkList: this.formConfig.checkList,
                },
            };
            this.stashTemplate = res.content.template;
            res.content.template.map((element) => {
                element.map((item) => {
                    if (item.controlName) {
                        this.$set(this.formData, item.controlName, item.value || "");
                    }
                    return item;
                });
                return element;
            });
            this.template = this.jsonCopy(this.stashTemplate);
            this.template[0] = [].concat(res.content.template[0]);
            this.formVisible()
            this.checkList = res.content.checkList || [];
            console.log("====表单template", this.stashTemplate);
        },
        // 返显表单数据
        showFormDate() {
            this.isEdit = true;
            this.miceInfo = this.formConfig.formData;
            for (let index = 0; index < this.template[0].length; index++) {
                let templateItem = this.template[0][index];
                let currentItem = this.miceInfo[templateItem.controlName] || "";
                console.log("====需要反显的字段", templateItem.type, templateItem.label, templateItem.controlName, currentItem);
                if (templateItem.type == "iSelectRest") {
                    this.formCallBack(currentItem, 0, templateItem);
                } else if (templateItem.type == "iCascaderRest") {
                    if (templateItem.controlName == "eventCity") {
                        this.$nextTick(() => {
                            this.$refs.eventCity[0].$children[0].reassign(currentItem, this.jsonCopy(currentItem).split("/").pop());
                        });
                    }
                } else if (templateItem.type == "iDatePicker") {
                    this.$nextTick(() => {
                        this.$refs[templateItem.controlName][0].$children[0].reassign(new Date(this.miceInfo.ExtData[templateItem.controlName]));
                    });
                } else if (templateItem.type == "multipleSelect") {
                    this.$nextTick(() => {
                        this.$refs[templateItem.controlName][0].$children[0].reassign(currentItem);
                    });
                } else {
                    this.formData[templateItem.controlName] = currentItem;
                }
                this.toggleRefresh();
            }
        },
        toggleRefresh() {
            setTimeout(() => {
                this.refreshKey = false;
                this.$nextTick(() => {
                    this.refreshKey = true;
                });
            }, 10);
        },
        onSubmit(values) {
            // 过滤values，去掉无效信息和SourceData,字符转换数字
            for (const key in values) {
                if (key == "undefined" || key.includes("SourceData")) {
                    delete values[key];
                } else if (this.template[0].find((x) => x.controlName == key).type == "number") {
                    values[key] = Number(values[key]);
                }
            }
            let context = values;
            let _this = this;
            console.log("====表单数据汇总", context, _this);
            // 验证表单数据
            let checkExpress = this.checkList.filter((x) => {
                try {
                    return eval(x.expr);
                } catch (error) {
                    console.log(error);
                }
            });

            let isCheckAllPass = true;
            if (checkExpress.length) {
                console.log("====命中表单验证规则", checkExpress);
                try {
                    checkExpress.map((express) => {
                        express.rules.map((rule) => {
                            if (eval(rule.expr)) {
                                // 通过验证
                            } else {
                                this.$itoast.fail(rule.errorMsg);
                                isCheckAllPass = false;
                            }
                        });
                    });
                } catch (error) {
                    console.log(error);
                }
            }
            if (isCheckAllPass) {
                this.$emit("send", context,this.isEdit);
            } else {
                this.loading = false;
            }
        },
        setFiledName(item) {
            this.fieldNames[item.controlName] = {
                text: item.txtName,
                value: item.valName,
                children: item.children,
            };
        },
        formCallBack(value, index, formItem) {
            // 表单数据赋值
            this.formData[formItem.controlName] = value.val || value.value || value;
            // 执行表单配置功能
            if (formItem.type == "iSelectRest") {
                this.formVisible(value, index, formItem)
            } else if (formItem.type == "automaticFill") {
                // 赋值其他表单项
                console.log("====需要自动填充内容", value, formItem.fillFormItem);
                this.formData[formItem.controlName] = value[formItem.controlName];
                formItem.fillFormItem.map((item) => {
                    this.formData[item.fillControlName] = value[item.fromValName];
                });
            }
        },
        toBack() {
            this.$emit("close");
        },
        formVisible(value, index, formItem) {
            // 根据表单项的值控制其他表单项的显隐藏
            let context = this.formData;
            console.log("====formitem的callback", formItem && formItem.controlName, value);
            this.template[0] = this.stashTemplate[0].filter((item) => {
                if (item.visibleRuleExpr) {
                    // 命中规则才显示
                    try {
                        return item.visibleRuleExpr.every((subItem) => eval(subItem));
                    } catch (error) {
                        console.log(error);
                    }
                } else {
                    return true;
                }
            });
        }
    },
};
</script>

<style lang="less" scoped>
.create-box {
    width: 100vw;
    height: 100%;
    padding-bottom: 0.34rem;
    box-sizing: border-box;
    background-color: #ffffff;

    .head-box {
        padding: 0 0.25rem 0 0.25rem;
        margin-bottom: 0.2rem;

        .back-icon {
            text-align: left;
            padding: 0.15rem 0;
        }

        .title {
            text-align: left;
            color: #13161b;
            font-size: 0.24rem;
        }
    }

    .cnt-box {
        padding: 0 0.25rem;

        .more-box {
            margin-bottom: 0.2rem;

            .more {
                display: flex;
                align-items: center;
                justify-content: center;

                .name {
                    color: #90939a;
                }

                svg {
                    display: block;
                }
            }

            .title {
                text-align: left;
                margin-bottom: 0.1rem;
            }
        }

        /deep/ .van-form {
            margin-top: 0.2rem;
        }
        /deep/ .van-button__text {
            font-size: 0.16rem;
        }
        .btn-box {
            margin-top: 0.3rem;
        }
    }
}
</style>
